import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import "commons/i18n";
import { useMode } from "theme";
import SecureLayout from "layout/secure";
import routes from "secureRoutes";
import NotFound from "pages/NotFound";
import LoadingContextProvider from "context/LoadingContext";

const App = () => {
  const [theme] = useMode();

  return (
    <ThemeProvider theme={theme}>
      <LoadingContextProvider>
        <CssBaseline />
        <Routes>
          <Route path="/secure/*" element={<SecureLayout routes={routes} />} />
          <Route path="/" element={<Navigate to="/secure/dashboard" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </LoadingContextProvider>
    </ThemeProvider>
  );
};

export default App;
