import { Box } from "@mui/material";
import React, { useState } from "react";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import LastPosition from "components/Maps/LastPosition";
import mockVehicles from "mock_vehicles.json";

const columnOptions = {
  disableColumnMenu: true,
  hideSortIcons: true,
  flex: 0.1,
};

const columns = [
  { field: "plate", headerName: "Placa", ...columnOptions },
  {
    field: "date",
    headerName: "Data",
    ...columnOptions,
    flex: 0.2,
  },
  { field: "address", headerName: "Endereço", ...columnOptions, flex: 1 },
  {
    field: "velocity",
    headerName: "Velocidade",
    ...columnOptions,
    valueFormatter: (value) => `${value} km/h`,
  },
  {
    field: "ignition",
    headerName: "Ignição",
    ...columnOptions,
    valueFormatter: (value) => (value ? "Ligada" : "Desligado"),
  },
  { field: "odometer", headerName: "Hodômetro", ...columnOptions, flex: 0.15 },
  { field: "driver", headerName: "Motorista", ...columnOptions, flex: 0.2 },
];

const LastLocation = () => {
  const [postions, setPositions] = useState([]);

  const handleFind = (vehicles) => {
    setPositions(
      mockVehicles.filter((vehicle) => vehicles?.includes(vehicle.plate))
    );
  };

  return (
    <Box m="20px" pb={5}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Ultima Posição" subtitle="" />
      </Box>
      <LastPosition positions={postions} buttonFind={handleFind} />
      {postions.length > 0 ? (
        <DataGrid
          rows={postions}
          columns={columns}
          hideFooter
          density="compact"
          sx={{ mt: 2 }}
        />
      ) : null}
    </Box>
  );
};

export default LastLocation;
