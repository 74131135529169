import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import mockCustomer from "mock_customers.json";

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const CustomerAutoComplete = ({ customer, setCustomer }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerFilter, setCustomerFilter] = useState("");

  useEffect(() => {
    let active = true;
    if (customerFilter.length >= 3) {
      setLoading(true);
      (async () => {
        await sleep(2e3);
        if (active) {
          setOptions(
            mockCustomer.filter((c) =>
              c.name.toLowerCase().includes(customerFilter.toLowerCase())
            )
          );
          setLoading(false);
        }
      })();
    }
  }, [customerFilter]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      sx={{ width: 500 }}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      value={customer}
      onChange={(_, newValue) => {
        setCustomer(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(event) => {
            setCustomerFilter(event.target.value);
          }}
          label="Nome Cliente"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

CustomerAutoComplete.propTypes = {
  customer: PropTypes.object,
  setCustomer: PropTypes.func,
};

export default CustomerAutoComplete;
