import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  useAdvancedMarkerRef,
  useApiIsLoaded,
  useMap,
} from "@vis.gl/react-google-maps";
import {
  Fab,
  Typography,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CustomerAutoComplete from "components/Autocomplete/Customer";
import VehicleOptions from "components/Options/Vehicle";

const containerStyle = {
  width: "100%",
  height: "70vh",
};

const center = {
  lat: -19.48875,
  lng: -46.548633,
};

const Marker = ({ data }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  );

  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <>
      <AdvancedMarker
        position={{ lat: data.lat, lng: data.lng }}
        ref={markerRef}
        onClick={handleMarkerClick}
      >
        <img src={data.icon} className="map-icon" />
        <span
          className={`label-last-location ${
            data.ignition ? "label-last-location-on" : "label-last-location-off"
          }`}
        >
          {data.plate}
        </span>
      </AdvancedMarker>

      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={handleClose} headerDisabled>
          <Typography>Data: {data.date}</Typography>
          <Typography>Endereço: {data.address}</Typography>
          <Typography>Velocidade: {data.velocity} Km/h</Typography>
          <Typography>
            Latitude: {data.lat} Longitude: {data.lng}
          </Typography>
        </InfoWindow>
      )}
    </>
  );
};

Marker.propTypes = {
  data: PropTypes.object,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilterOptions = ({ handleClose, handleFind, open }) => {
  const [customer, setCustomer] = useState(null);
  const [vehicles, setVehicles] = useState([]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Filtrar
          </Typography>
          <Button
            autoFocus
            onClick={() => handleFind(vehicles)}
            color="secondary"
            disabled={vehicles.length == 0}
          >
            Pesquisar
          </Button>
        </Toolbar>
      </AppBar>
      <Box pl={5} pt={2}>
        <CustomerAutoComplete setCustomer={setCustomer} customer={customer} />
        <VehicleOptions
          customer={customer}
          setVehicles={setVehicles}
          vehicles={vehicles}
        />
      </Box>
    </Dialog>
  );
};

FilterOptions.propTypes = {
  handleClose: PropTypes.func,
  handleFind: PropTypes.func,
  open: PropTypes.bool,
};

const MapHandler = ({ positions }) => {
  const map = useMap();
  // eslint-disable-next-line no-undef
  const bounds = new google.maps.LatLngBounds();

  useEffect(() => {
    if (!map || positions.length < 1) return;

    positions.forEach((p) => {
      bounds.extend({ lat: p.lat, lng: p.lng });
    });

    map.fitBounds(bounds);
  }, [map, positions]);
};

MapHandler.propTypes = {
  positions: PropTypes.array,
};

const LastPosition = ({ positions, buttonFind }) => {
  const apiIsLoaded = useApiIsLoaded();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFind = (vehicles) => {
    buttonFind(vehicles);
    setOpen(false);
  };

  return !apiIsLoaded ? (
    <>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_KEY}>
        <Map
          mapId="d75acbc4758692d4"
          style={containerStyle}
          defaultCenter={center}
          defaultZoom={6}
          gestureHandling={"greedy"}
          mapTypeId="hybrid"
        >
          <Fab
            color="default"
            size="medium"
            variant="extended"
            sx={{
              position: "absolute",
              top: 10,
              right: 60,
            }}
            onClick={handleClickOpen}
          >
            <SearchIcon /> Pesquisar
          </Fab>
          {positions.map((prop, key) => (
            <Marker key={key} data={prop} />
          ))}
          <MapHandler positions={positions} />
        </Map>
      </APIProvider>
      <FilterOptions
        handleClose={handleClose}
        open={open}
        handleFind={handleFind}
      />
    </>
  ) : (
    <></>
  );
};

LastPosition.propTypes = {
  positions: PropTypes.array.isRequired,
  buttonFind: PropTypes.func.isRequired,
};

export default React.memo(LastPosition);
