import React from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import { tokens } from "theme";

const Header = ({ title, subtitle }) => {
  const colors = tokens();
  return (
    <Box mb="20px" mt="-50px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Header;
