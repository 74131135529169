import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import mockVehicles from "mock_vehicles.json";

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const VehicleOptions = ({ customer, vehicles, setVehicles }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    let active = true;
    setLoading(true);
    (async () => {
      await sleep(1e3);
      if (active) {
        setOptions(
          mockVehicles.filter((vehicle) => vehicle.customer_id === customer?.id)
        );
        setLoading(false);
      }
    })();
  }, [customer]);

  useEffect(() => {
    setSelectedAll(vehicles?.length === options.length);
  }, [vehicles, options]);

  if (!customer) {
    return <></>;
  }

  const handleCheckAll = () => {
    if (!selectedAll) {
      setVehicles(options.map((vehicle) => vehicle.plate));
    } else {
      setVehicles([]);
    }
    setSelectedAll(!selectedAll);
  };

  return (
    <>
      {loading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <Box p={2}>
          <Typography variant="h2" fontWeight="bold" sx={{ m: "0 0 5px 0" }}>
            Selecione os Veículos
          </Typography>
          <Checkbox
            sx={{ ml: "10px" }}
            checked={selectedAll}
            onChange={handleCheckAll}
          />
          Todos
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              {options.map((value, key) => {
                return (
                  <FormControlLabel
                    key={key}
                    sx={{ m: "10px", minWidth: "400px" }}
                    control={
                      <Checkbox
                        checked={vehicles.includes(value.plate)}
                        onChange={(event) =>
                          event.target.checked
                            ? setVehicles([...vehicles, value.plate])
                            : setVehicles([
                                ...vehicles.filter(
                                  (plate) => plate !== value.plate
                                ),
                              ])
                        }
                      />
                    }
                    label={`${value.plate} - ${value.mark} ${value.model}`}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Box>
      )}
    </>
  );
};

VehicleOptions.propTypes = {
  customer: PropTypes.object,
  vehicles: PropTypes.array.isRequired,
  setVehicles: PropTypes.func.isRequired,
};

export default VehicleOptions;
