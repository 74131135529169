import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { tokens } from "theme";

const Footer = () => {
  const colors = tokens();
  return (
    <Paper
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        background: `${colors.primary[400]} !important`,
      }}
      component="footer"
      square
    >
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "flex",
          mb: 1,
        }}
        width="100%"
      >
        <Typography variant="caption">
          Copyright &copy; {1900 + new Date().getYear()}. Softpro Tecnologia.
          Todos os direitos reservados.
        </Typography>
      </Box>
    </Paper>
  );
};

export default Footer;
