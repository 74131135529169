import React, { useEffect, useState } from "react";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { hasAuthParams, useAuth } from "react-oidc-context";
import PropTypes from "prop-types";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
import { Route, Routes } from "react-router-dom";
import Footer from "components/Footer";
import NotFound from "pages/NotFound";
import { ToastContainer } from "react-toastify";
import { useLoadingContext } from "context/LoadingContext";

const SecureLayout = ({ routes }) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const role = auth?.user?.profile?.role;
  const { isLoading } = useLoadingContext();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.separator) {
        return null;
      }
      if (prop.category) {
        if (!prop.roles.length || prop.roles.includes(role)) {
          return getRoutes(prop.items);
        } else {
          return null;
        }
      } else {
        if (!prop.roles.length || prop.roles.includes(role)) {
          return (
            <Route path={prop.path} Component={prop.component} key={key} />
          );
        } else {
          return null;
        }
      }
    });
  };

  useEffect(() => {
    if (
      !(
        hasAuthParams() ||
        auth.isAuthenticated ||
        auth.activeNavigator ||
        auth.isLoading ||
        hasTriedSignin
      )
    ) {
      void auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }

  return (
    <>
      <ToastContainer
        autoClose={5000}
        newestOnTop={true}
        pauseOnFocusLoss
        hideProgressBar
        theme="light"
      />
      <div className="app">
        <Sidebar routes={routes} />
        <main className="content">
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : null}
          <Topbar />
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  );
};

SecureLayout.propTypes = {
  routes: PropTypes.array,
};

export default SecureLayout;
