import { Box, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useAuth } from "react-oidc-context";
import LogoutIcon from "@mui/icons-material/Logout";

const Topbar = () => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.removeUser();
    auth.signoutRedirect();
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" pr={2} pt={1}>
        <Box display="flex" />
        <Box display="flex">
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton
            id="person-button"
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
          >
            <PersonOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Menu
        id="account-menu"
        MenuListProps={{
          "aria-labelledby": "person-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
            Sair
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Topbar;
