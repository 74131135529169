import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const LoadingContext = createContext();

export const LoadingContextProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error(
      "useLoadingContext must be used within the scope of LoadingContextProvider"
    );
  }
  return context;
};

LoadingContextProvider.propTypes = {
  children: PropTypes.any,
};

export default LoadingContextProvider;
