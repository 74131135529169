import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import img404 from "assets/img/404.jpg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  const handelBack = () => {
    toast.info("message");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        paddingTop: "calc(100vh - 50%)",
      }}
    >
      <Container maxWidth="sm">
        <img src={img404} height={300} className="img-round-10" />
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{t("general.pageNotFound")}</Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <Button variant="contained" onClick={handelBack}>
            Back Home
          </Button>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotFound;
