import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import EmergencyShareOutlinedIcon from "@mui/icons-material/EmergencyShareOutlined";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import FenceOutlinedIcon from "@mui/icons-material/FenceOutlined";
import SimCardOutlinedIcon from "@mui/icons-material/SimCardOutlined";
import SettingsCellOutlinedIcon from "@mui/icons-material/SettingsCellOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";

import Dashboard from "./pages/secure/Dashboard";
import LastLocation from "./pages/secure/LastLocation";

export const roles = [
  "ADMINISTRADOR",
  "ATENDENTE",
  "CLIENTE_FROTISTA",
  "CONDUTOR",
  "INSTALADOR_REVENDA",
  "ACESSO_REMOTO",
  "REPRESENTANTE_COMERCIAL",
  "ASSOCIACAO",
  "CADASTRO",
  "GERENCIA_DE_RISCO",
  "GENERICO",
  "USUARIO_ADICIONAL_CLIENTE",
  "ATENDENTE",
  "USUARIO_ADICIONAL_CLI_PESQUISA",
];

const routes = [
  {
    name: "Dashboard",
    layout: "/secure",
    path: "/dashboard",
    icon: <HomeOutlinedIcon />,
    component: Dashboard,
    roles: [],
  },
  {
    name: "Alertas",
    layout: "/secure",
    path: "/alerts",
    icon: <EmergencyShareOutlinedIcon />,
    component: Dashboard,
    roles: [],
  },
  {
    name: "Registrar Atendimento",
    layout: "/secure",
    path: "/attendance-recorder",
    icon: <PermPhoneMsgOutlinedIcon />,
    component: Dashboard,
    roles: [],
  },
  {
    name: "Ultima Posição",
    layout: "/secure",
    path: "/last-position",
    icon: <ShareLocationOutlinedIcon />,
    component: LastLocation,
    roles: [],
  },
  {
    separator: true,
  },
  {
    name: "Cadastro",
    category: true,
    roles: [],
    items: [
      {
        name: "Cerca Eletronica",
        layout: "/secure",
        path: "/registration/catechizing",
        icon: <FenceOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Chip",
        layout: "/secure",
        path: "/registration/online",
        icon: <SimCardOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Equipamento",
        layout: "/secure",
        path: "/registration/user",
        icon: <SettingsCellOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Ordem Serviço",
        layout: "/secure",
        path: "/registration/class",
        icon: <HomeRepairServiceOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Usuário",
        layout: "/secure",
        path: "/registration/class",
        icon: <ManageAccountsOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Veículos",
        layout: "/secure",
        path: "/registration/class",
        icon: <DirectionsCarOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
    ],
  },
  {
    separator: true,
  },
  {
    name: "Relatórios",
    category: true,
    roles: [],
    items: [
      {
        name: "Alertas",
        layout: "/secure",
        path: "/report/catechizing",
        icon: <ReportGmailerrorredOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Atendimento",
        layout: "/secure",
        path: "/report/classes",
        icon: <ContactPhoneOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Rota",
        layout: "/secure",
        path: "/report/classes",
        icon: <PinDropOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
    ],
  },
  {
    separator: true,
  },
  {
    name: "Configurações",
    category: true,
    roles: [],
    items: [
      {
        name: "Comandos",
        layout: "/secure",
        path: "/config/portal",
        icon: <CorporateFareOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
      {
        name: "Eventos",
        layout: "/secure",
        path: "/config/catechetical-year",
        icon: <MarkunreadMailboxOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
    ],
  },
  {
    separator: true,
  },
  {
    name: "Auditoria",
    category: true,
    roles: [],
    items: [
      {
        name: "Relatório de Acesso",
        layout: "/secure",
        path: "/config/portal",
        icon: <VerifiedUserOutlinedIcon />,
        component: Dashboard,
        roles: [],
      },
    ],
  },
];

export default routes;
